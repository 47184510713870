import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useState } from "react";
import {
    dangerHex,
    darkHex,
    lightHex,
    mediumHex,
    primaryHex,
    secondaryHex,
    successHex,
    warningHex,
} from "../constants/colors";
import { PlacementSide, Tooltip } from "./Tooltip/Tooltip";
import { computed } from "../util/functions";
import { CSSProperties } from "@mui/material/styles/createMixins";

export type IconColor = "primary" | "secondary" | "success" | "danger" | "warning" | "dark" | "light" | "medium";

interface SharedIconWithTooltipProps {
    onClick?: (event: React.MouseEvent) => void;
    tooltipText?: string;
    icon?: IconProp;
    imageIconPath?: string;
    color?: IconColor;
    iconStyle?: CSSProperties;
    iconClassName?: string;
    onlyTitle?: boolean;
    placement?: PlacementSide;
    disableMobileClick?: boolean;
    children?: ReactNode;
}

interface IIconProp extends SharedIconWithTooltipProps {
    icon: IconProp;
    imageIconPath?: never;
}

interface IImageProp extends SharedIconWithTooltipProps {
    icon?: never;
    imageIconPath: string;
}

export type IIconWithTooltipProps = IIconProp | IImageProp;

function getColorCode(color?: IconColor) {
    switch (color) {
        case "primary":
            return primaryHex;
        case "secondary":
            return secondaryHex;
        case "success":
            return successHex;
        case "warning":
            return warningHex;
        case "danger":
            return dangerHex;
        case "dark":
            return darkHex;
        case "light":
            return lightHex;
        case "medium":
            return mediumHex;
    }
    return undefined;
}

export function IconWithTooltip(props: IIconWithTooltipProps) {
    const [ref, setRef] = useState<Element | null>(null);

    const iconComponent = computed(() => {
        if (props.icon) {
            return (
                <FontAwesomeIcon
                    ref={setRef}
                    icon={props.icon}
                    className={props.iconClassName}
                    style={{ ...props.iconStyle, color: getColorCode(props.color) }}
                    title={props.onlyTitle ? props.tooltipText : undefined}
                    onClick={props.onClick}
                />
            );
        } else if (props.imageIconPath) {
            return (
                <img
                    ref={setRef}
                    src={props.imageIconPath}
                    className={props.iconClassName}
                    style={{ ...props.iconStyle, color: getColorCode(props.color) }}
                    title={props.onlyTitle ? props.tooltipText : undefined}
                    onClick={props.onClick}
                    alt={"Maschinenring-Logo"}
                />
            );
        }
        return null;
    });

    return (
        <>
            {iconComponent}
            {!props.onlyTitle && (props.tooltipText || props.children) && (
                <Tooltip
                    trigger={ref}
                    placement={props.placement ?? "right"}
                    disableMobileClick={props.disableMobileClick}>
                    {props.tooltipText || props.children}
                </Tooltip>
            )}
        </>
    );
}
