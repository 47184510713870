import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import "./tooltip.scss";
import clsx from "clsx";
import { usePortal } from "../../util/customHooks/usePortal";
import { isPlatform } from "@ionic/react";

export type PlacementSide = "top" | "right" | "bottom" | "left";

interface ITooltipProps {
    /**
     * HTML element that will trigger the tooltip
     * @example
     * const trigger = useRef<HTMLButtonElement>(null)
     * <button ref={trigger}>Hover me</button>
     * <Tooltip trigger={trigger.current} placement="right">Only visible when hovering button</Tooltip>
     */
    trigger: Element | null;
    /**
     * Open tooltip after the given amount of time (in milliseconds)
     * @default 200
     */
    delay?: number;
    /**
     * Tooltip placement side
     */
    placement: PlacementSide;
    className?: string;
    style?: CSSProperties;
    disableMobileClick?: boolean;
}

export const Tooltip: FC<React.PropsWithChildren<ITooltipProps>> = props => {
    const portalElement = usePortal();
    const [isHovering, setIsHovering] = useState(false);

    const delay = props.delay ?? 200;

    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        let enableTimeout: NodeJS.Timeout;

        const clearEnableTimeout = () => {
            if (enableTimeout) {
                clearTimeout(enableTimeout);
            }
        };

        const tooltipEnableListener = () => {
            clearEnableTimeout();
            enableTimeout = setTimeout(() => {
                if (mounted.current) {
                    setIsHovering(true);
                }
            }, delay);
        };
        const tooltipDisableListener = () => {
            clearEnableTimeout();
            setIsHovering(false);
        };

        if (props.trigger) {
            if (!isPlatform("mobile") || !props.disableMobileClick) {
                props.trigger.addEventListener("mouseenter", tooltipEnableListener);
            }
            props.trigger.addEventListener("mouseleave", tooltipDisableListener);
            if (!props.disableMobileClick) {
                props.trigger.addEventListener(
                    "touchstart",
                    tooltipEnableListener,
                    { passive: true }
                ); // Mobile
            }
        }

        return () => {
            if (!isPlatform("mobile") || !props.disableMobileClick) {
                props.trigger?.removeEventListener("mouseenter", tooltipEnableListener);
            }
            props.trigger?.removeEventListener("mouseleave", tooltipDisableListener);
            if (!props.disableMobileClick) {
                props.trigger?.removeEventListener("touchstart", tooltipEnableListener);
            }
        };
    }, [props.trigger, delay, props.disableMobileClick]);

    const getContainerStyles = (): CSSProperties => {
        if (!props.trigger) {
            return {};
        }

        const triggerBounds = props.trigger.getBoundingClientRect();

        const positionStyles: CSSProperties = {
            top: triggerBounds.y,
            left: triggerBounds.x,
        };

        const horizontalTranslate = `calc(max(-50%, ${triggerBounds.x * -1}px) + ${triggerBounds.width * 0.5}px)`;
        const verticalTranslate = `calc(-50% + ${triggerBounds.height * 0.5}px)`;

        switch (props.placement) {
            case "top":
                return {
                    ...positionStyles,
                    transform: `translate3d(${horizontalTranslate}, -100%, 0)`,
                    transformOrigin: "bottom",
                    maxWidth: window.innerWidth,
                };
            case "right":
                return {
                    ...positionStyles,
                    transform: `translate3d(calc(${triggerBounds.width}px), ${verticalTranslate}, 0)`,
                    transformOrigin: "left",
                    maxWidth: window.innerWidth - triggerBounds.width - triggerBounds.x,
                };
            case "bottom":
                return {
                    ...positionStyles,
                    transform: `translate3d(${horizontalTranslate}, ${triggerBounds.height}px, 0)`,
                    transformOrigin: "top",
                    maxWidth: `calc(100vw - ${triggerBounds.width}px)`,
                };
            case "left":
                return {
                    ...positionStyles,
                    transform: `translate3d(-100%, ${verticalTranslate}, 0)`,
                    transformOrigin: "right",
                    maxWidth: triggerBounds.x,
                };
            default:
                return {};
        }
    };

    const container = (
        <div
            className={clsx("tooltip", props.className)}
            style={{
                ...props.style,
                ...getContainerStyles(),
            }}>
            <div
                className={clsx(
                    "tooltip__inner",
                    isHovering ? "tooltip--open" : "tooltip--close",
                    props.placement === "top" && "tooltip--placement-top",
                    props.placement === "right" && "tooltip--placement-right",
                    props.placement === "bottom" && "tooltip--placement-bottom",
                    props.placement === "left" && "tooltip--placement-left"
                )}>
                {props.children}
            </div>
        </div>
    );

    return createPortal(container, portalElement);
};
