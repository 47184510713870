import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { IconColor, IconWithTooltip } from "../../../IconWithTooltip";

interface IFormControlTooltipProps {
    type: FormControlTooltipType;
    children: React.ReactNode;
}
export const FormControlTooltip = (props: IFormControlTooltipProps) => {
    const { type, children } = props;

    return (
        <IconWithTooltip icon={getTooltipIcon(type)} color={getTooltipColor(type)}>
            {children}
        </IconWithTooltip>
    );
};

type FormControlTooltipType = "info" | "warning";

function getTooltipIcon(type: FormControlTooltipType): IconProp {
    switch (type) {
        case "info":
            return faQuestionCircle;
        case "warning":
            return faExclamationTriangle;
    }
}

function getTooltipColor(type: FormControlTooltipType): IconColor {
    switch (type) {
        case "info":
            return "dark";
        case "warning":
            return "warning";
    }
}
