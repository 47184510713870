import clsx from "clsx";
import React from "react";
import { ProvideFormControl } from "../../../FormControlContext/FormControlContext";
import "./formControlRoot.scss";

interface IFormControlRootOwnProps {
    /**
     * @default "stacked"
     */
    layout?: "stacked" | "inline";
}
interface IFormControlRootProps
    extends IFormControlRootOwnProps,
    Omit<React.ComponentPropsWithoutRef<"fieldset">, keyof IFormControlRootOwnProps> { }
export const FormControlRoot = (props: IFormControlRootProps) => {
    const { layout = "stacked", ...restProps } = props;

    return (
        <ProvideFormControl>
            <fieldset
                {...restProps}
                className={clsx(
                    "form-control-root",
                    {
                        "form-control-root--stacked": layout === "stacked",
                        "form-control-root--inline": layout === "inline",
                    },
                    restProps.className
                )}
            />
        </ProvideFormControl>
    );
};
