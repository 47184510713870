import clsx from "clsx";
import React from "react";
import { useFormControlContext } from "../../../FormControlContext/FormControlContext";
import "./formControlLabel.scss";

interface IFormControlLabelProps extends React.ComponentPropsWithoutRef<"label"> { }
export const FormControlLabel = (props: IFormControlLabelProps) => {
    const { htmlFor: controlledHtmlFor, ...restProps } = props;
    const { id: uncontrolledHtmlFor } = useFormControlContext();

    const htmlFor = controlledHtmlFor || uncontrolledHtmlFor;

    return <label {...restProps} htmlFor={htmlFor} className={clsx("form-control-label", restProps.className)} />;
};
